<template>
  <div class="success">
    <img src="@/assets/imgs/sucImg.png" alt="">
    <div class="title">Thank You for your<br/>purchase!</div>
    <div class="content">Product will be shipped to you soon!<br/>Kindly check your email address<br/>for more information.</div>
    <div class="btn" @click="router.push('/')">Start</div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'

  const router = useRouter()
</script>

<style lang="less" scoped>
.success{
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .title{
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .content{
    color: #06C8A1;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 50px;
  }
  .btn{
    width: calc(100% - 48px);
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 auto;
    background-color: #06C8A1;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 500;
    color: #000000;
  }
  .btn:hover{
    background-color: #33F88F;
  }
}
</style>
